import {StoreSettingsApi} from '../apis/StoreSettingsApi';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {StoreSettings} from '@wix/wixstores-graphql-schema-node';

export class StoreSettingsService {
  private readonly storeSettingsApi: StoreSettingsApi;
  private storeSettings: StoreSettings = {} as StoreSettings;
  private readonly taxOnProduct: boolean = false;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.storeSettingsApi = new StoreSettingsApi({siteStore});
    this.taxOnProduct = siteStore.priceSettings?.taxOnProduct ?? /* istanbul ignore next */ false;
  }

  public async fetch({preload}: {preload?: boolean}) {
    this.storeSettings = preload
      ? ({taxOnProduct: this.taxOnProduct} as StoreSettings)
      : await this.storeSettingsApi.getStoreSettings();
    return this.storeSettings;
  }

  public getFetchedData() {
    return this.storeSettings;
  }
}
