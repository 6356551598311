import {BaseCartController} from '../../domain/controllers/BaseCartController';
import {settingsParams} from './settingsParams';
import {ICartStyleSettings} from '../../types/app.types';
import {SIDE_CART_ORIGIN} from '../../common/constants';
import {getFreeTextKeys} from '../../domain/utils/settings.utils';

export class SideCartController extends BaseCartController {
  protected getOrigin() {
    return SIDE_CART_ORIGIN;
  }
  protected getDefaultSettings() {
    return {} as ICartStyleSettings;
  }

  /* istanbul ignore next */
  public exports() {
    return {};
  }

  public getFreeTexts(): string[] {
    return getFreeTextKeys(settingsParams);
  }
}
